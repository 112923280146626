import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule, MatMenuModule, MatIconModule, MatButtonModule,
        MatDialogModule, MatInputModule, MatSidenavModule, MatTooltipModule, MatCardModule, MatTableModule, MatDividerModule, MatTabsModule, MatGridListModule,
        MatRadioModule, MatSnackBarModule, MatChipsModule, MatListModule, MatRippleModule, MatExpansionModule, MatSelectModule, MatSliderModule, MatCheckboxModule} from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatSidenavModule,
    MatTooltipModule,
    MatTableModule,
    MatDividerModule,
    MatTabsModule,
    MatGridListModule,
    MatRadioModule,
    MatSnackBarModule,
    MatChipsModule,
    MatListModule,
    MatRippleModule,
    MatExpansionModule,
    MatSelectModule,
    MatSliderModule,
    MatCheckboxModule
  ],
  exports: [
    MatToolbarModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatDialogModule,
    MatInputModule,
    MatSidenavModule,
    MatTooltipModule,
    MatTableModule,
    MatDividerModule,
    MatTabsModule,
    MatGridListModule,
    MatRadioModule,
    MatSnackBarModule,
    MatChipsModule,
    MatListModule,
    MatRippleModule,
    MatExpansionModule,
    MatSelectModule,
    MatSliderModule,
    MatCheckboxModule
  ],
  declarations: []
})
export class MaterialModule { }
