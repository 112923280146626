import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { TipsListComponent } from './components/tips-list/tips-list.component';
import { PdfListComponent } from './components/pdf-list/pdf-list.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'general'
  },
  {
    path: '', 
    component: LandingPageComponent,
    children: [
      {
        path: 'general',
        component: TipsListComponent
      },
      {
        path: 'memos',
        component: PdfListComponent
      },
      {
        path: 'awards',
        component: PdfListComponent
      },
      {
        path: 'letters',
        component: PdfListComponent
      },
      {
        path: 'leaders',
        component: PdfListComponent
      },
      {
        path: 'instructions',
        component: PdfListComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
