import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'tip-list-item',
  templateUrl: './tip-list-item.component.html',
  styleUrls: ['./tip-list-item.component.css'],
  encapsulation: ViewEncapsulation.None,
  styles: [`
    .my-custom-class .tooltip-inner {
      background-color: darkgreen;
      font-size: 125%;
    }
    .my-custom-class .arrow::before {
      border-top-color: darkgreen;
    }
  `]
})
export class TipListItemComponent implements OnInit {
  // @Input() tipText: string;
  // @Input() infoTooltip;
  // @Input() bulbTooltip;
  @Input() data: any;
  @Input() index: number;

  testTooltip = "Wow, <b>test</b>";

  constructor() { }

  ngOnInit() {
  }

}
