import { Component, OnInit } from '@angular/core';
import { WritingTipsService } from '../../services/writing-tips.service';
@Component({
  selector: 'tips-list',
  templateUrl: './tips-list.component.html',
  styleUrls: ['./tips-list.component.css']
})

export class TipsListComponent implements OnInit {

  constructor(private tipService: WritingTipsService) {  
  }

  ngOnInit() {
  }

}
