import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './app-header.component.html',
  styleUrls: ['./app-header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppHeaderComponent implements OnInit {
  navLinks = [
    {
      label: 'NCO Writing Guide',
      path: '/general',
      // isActive: true
    },
    {
      label: 'Memos',
      path: '/memos',
      // isActive: false
    },
    {
      label: 'Awards',
      path: '/awards',
      // isActive: false
    },
    {
      label: 'Letters',
      path: '/letters',
      // isActive: false
    },
    {
      label: 'Leaders Notes',
      path: '/leaders',
      // isActive:false
    },
  ]

  constructor() { }

  ngOnInit() {
  }

}
