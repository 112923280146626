import { Injectable } from '@angular/core';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WritingTipsService {

  activeChildRoute: string;

  constructor(private router: Router) {
    router.events.subscribe( (event: Event) => {

      if (event instanceof NavigationStart) {

      }

      if (event instanceof NavigationEnd) {
        console.log(event.url);
        this.activeChildRoute = event.url;
        if(this.activeChildRoute[0] == '/') {
          this.activeChildRoute = this.activeChildRoute.substr(1);
        }
      }

      if (event instanceof NavigationError) {
          console.log(event.error);
      }
    });
  }

  getExampleList() {
    switch(this.activeChildRoute) {
      case 'general':
        break;
      case 'memos':
        return this.exampleMemos;
      case 'awards':
        return this.examplePdfs;
      case 'letters':
        return this.exampleDocs;
      case 'leaders':
        return this.exampleLeadersNote;
    }
  }

  examplePdfs = [
    {
      name: "Good Example",
      file: "assets/goodMemo.pdf"
    },
    {
      name: "Poor Example",
      file: "assets/poorMemo.pdf"
    }
  ];

  exampleMemos = [
    {
      name: "Different Good Memo Example",
      file: "assets/goodMemo.pdf"
    },
    {
      name: "Different Poor Memo Example",
      file: "assets/poorMemo.pdf"
    }
  ];

  exampleDocs = [
    {
      name: "Example Doc1",
      file: "assets/goodMemo.pdf"
    },
    {
      name: "Example Doc2",
      file: "assets/poorMemo.pdf"
    }
  ];
  exampleLeadersNote =[
    {
      name: "Leaders Notes Tool",
      file: "assets/LeaderNotes.pdf"
    },
    {
      name: "Leaders Notes Instructions",
      file: "assets/LeaderNoteToolSlides.pdf"
    }  
  ];

  writingTips = [
    {
      standardText: "When writing any Army document, the first goal is to get something down on paper."
    },
    {
      standardText: "First drafts do not have to be perfect.",
      headerRow: true,
      infoHeader: 'Info',
      bulbHeader: 'Hints',
    },
    // {
    //   headerRow: true,
    //   infoHeader: 'Info',
    //   bulbHeader: 'Hints',
    // },
    {
      name: 'Write your draft',
      infoTip: "<p>You will need to revise, and that is an important next step.</p>",
      bulbTip: "<p>Include the important information but do not worry about format.</p>",
    },
    {
      standardText: "Once you have your first rough draft, be sure to check for the following twelve key writing requirements"
    },
    {
      name: 'Can you point to where you cover each of the 5 W’s?',
      infoTip: "<p>Who, what, where, when, why?</p>",
      bulbTip: "<p>Example: SGT Johnson reported that PVT Smith arrived late for the 1000 training at the Soldier Development Center on Wednesday because PVT Smith’s car would not start.</p>"
    },
    {
      name: 'Is your main point up front where it belongs or is it buried in the middle or hiding at the end?',
      infoTip: "<p>BLUF (bottom line up front)</p>"+
      "<p>Place conclusions and recommendations at the beginning of the text, rather than the end, in order to facilitate rapid decision making.</p>",
      bulbTip: "<p>The BLUF should quickly answer the five Ws: who, what, where, when, and why.</p>",
    },
    {
      name: 'Do you support each claim with evidence?',
      infoTip: "<p>Use examples to clarify your statements.</p>",
      bulbTip:"<p>Include specific observations or references.</p>"
    },
    {
      name: 'Are your paragraphs short enough to be easily read at a glance? ',
      infoTip: "<p>Write paragraphs that average 6 to 7 sentences in length.</p>",
      bulbTip: "<p>Try using Microsoft Word’s readability checklist. See the instructions for how to setup this feature in <a href='https://support.office.com/en-us/article/test-your-document-s-readability-85b4969e-e80a-4777-8dd3-f7fc3c8b3fd2#__toc342546555' target='_blank'>Microsoft Word</a></p>"
      // bulbTip:"<p>Try using Microsoft Word’s readability checklist. See the instructions for how to setup this feature in Microsoft Word.</p>"
    },
    {
      name: 'Are your sentences 15 or fewer words?',
      infoTip: "<p>Shorten longer sentences or split them into two sentences.</p>",
      bulbTip:"<p>Try using Microsoft Word’s readability checklist.</p>"
    },
    {
      name: 'Do you identify who is doing what at the start of your sentence?',
      infoTip: "<p>Write in active voice. Active voice means putting the actor in the subject position and the action in the verb.</p>",
      bulbTip:"<p>Use: 3rd Platoon will secure the perimeter.</p>"+
      "<p>Instead of: The perimeter will be secured by the 3rd Platoon.</p>"
    },
    {
      name: 'Do you make reading easy with short words?',
      infoTip: "<p>Words should have three syllables or less.</p>",
      bulbTip:'<p>Try using Microsoft Word’s readability checklist.</p>'
    },
    {
      name: 'Do you avoid jargon, including acronyms?',
      infoTip: "<p>Use jargon, including acronyms, carefully.</p>",
      bulbTip:"<p>Example: Tighten up your shot group, Soldier</p>"+
      "<p>If you are in doubt, use everyday words (even if this means using more words), and spell out acronyms on first use.  It is better to use more words than to confuse your reader.</p>"
    },
    {
      name: 'Do you use personal pronouns like I, you, and they?',
      infoTip: "<p>I, you, he, she, it, we, they</p>",
      bulbTip:"<p>When you are referring to yourself, use “I” or “me.” When referring to your group or company, use “we” or “us.” Use “you” for the person you are talking to – just like you do in conversation.</p>"
    },
    {
      name: 'Have you read what you have written out loud?',
      infoTip: "<p>Check for flow. Is this easy to follow?</p>",
      bulbTip:"<p>Check the message. Does what you wrote make sense?</p>"
    },
    {
      name: 'Have you used spell and grammar check? Have you asked someone else to review your writing?',
      infoTip: "<p>Proof reading is an important habit.  Always review your work for errors.</p>",
      bulbTip:"<p>Errors or mistakes in these areas not only detract from your writing, but may also turn your reader into an editor.  Once the reader sees one error, it may become instinctive to look for more errors instead of reading the correspondence for its intended message.</p>"
    },
    {
      name: 'Have you used no more than one page for correspondence?',
      infoTip: "<p>Limit length to one page for most correspondence.</p>",
      bulbTip:"<p>Try to avoid multiple-page memorandums. When necessary, consider using enclosures for additional information.</p>"
    }
  ]
}
