import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from './material/material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { AppHeaderComponent } from './components/app-header/app-header.component';
import { TipsListComponent } from './components/tips-list/tips-list.component';
import { TipListItemComponent } from './components/tip-list-item/tip-list-item.component';
import { PdfListComponent } from './components/pdf-list/pdf-list.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    AppHeaderComponent,
    TipsListComponent,
    TipListItemComponent,
    PdfListComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    FlexLayoutModule,
    AppRoutingModule,
    MaterialModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
