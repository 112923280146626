import { Component, OnInit } from '@angular/core';
import { WritingTipsService } from '../../services/writing-tips.service';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'pdf-list',
  templateUrl: './pdf-list.component.html',
  styleUrls: ['./pdf-list.component.css']
})
export class PdfListComponent implements OnInit {
  list: any = {
    header: 'Example Documents',
    subHeader: 'Memos',
    items: []
  };
  constructor(private router: Router, private tipService: WritingTipsService) {
    // this.list.subHeader = tipService.activeChildRoute;
    // router.events.subscribe( (event: Event) => {

    //   if (event instanceof NavigationStart) {

    //   }

    //   if (event instanceof NavigationEnd) {
    //     let currentUrl = event.url;
    //     if(currentUrl[0] == '/') {
    //       currentUrl = currentUrl.substr(1);
    //     }
    //     this.list.subheader = currentUrl;
    //   }

    //   if (event instanceof NavigationError) {
    //   }
    // });
  }

  ngOnInit() {
    this.list.subHeader = this.tipService.activeChildRoute;
    this.list.items = this.tipService.getExampleList();
  }

}
